import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/not-authorized',
    name: 'notAuthorized',
    component: () => import('../views/NotAuthorizedView.vue'),
    props: false,
  },
  {
    path: '/',
    name: 'splashView',
    component: () => import('../views/SplashView.vue'),
    props: false,
  },
  {
    path: '/clock-in',
    name: 'clockIn',
    component: () => import('../views/ClockIn.vue'),
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: '/home/:id?',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL || '/',
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');
  const isAuthenticated = !!token;

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ path: '/not-authorized' });
  } else {
    next();
  }
});


export default router
